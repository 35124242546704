import axios from 'axios'
import { AuthModel, ResendOTPModel, UserModel } from './_models'
import { APIResponse } from '../../../pages/acccount/Account.types'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_DETAIL = `${API_URL}/user/me`
export const USER_NOTIFICATION = `${API_URL}/user/me/notification-token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/admin/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/admin/reset-password`

export const VERIFY_OTP = `${API_URL}/auth/verify-otp`
export const RESEND_OTP = `${API_URL}/auth/resend-otp`

// Server should return AuthModel
export function login(email: string, password: string, device_code?: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
    device_code,
    role: 'Admin',
  })
}

// Server should return AuthModel
export function register(
  email: string,
  fullname: string,
  password: string,
  cpassword: string,
  role: Number
) {
  return axios.post(REGISTER_URL, {
    email,
    name: fullname,
    password,
    confirm_password: cpassword,
    role: 1,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function userNotification(token: string) {
  return axios.post<APIResponse>(USER_NOTIFICATION, {
    token,
  })
}

export function getUser() {
  return axios.get<{ result: UserModel }>(GET_USER_DETAIL)
}

export function authVerifyOtpAPI(body: any) {
  return axios.post<AuthModel>(VERIFY_OTP, body)
}

export function authResendOtpAPI(body: any) {
  return axios.post<ResendOTPModel>(RESEND_OTP, body)
}
