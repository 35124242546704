/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { ILayout, useLayout } from '../../core'

const Footer = () => {
  const { config } = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='text-dark order-2 order-md-1'>
        <p className='text-gray-800'>
          Copyright &copy; {new Date().getFullYear().toString()} Call Genie FZE
          LLC
          {/* <a href="https://simplesolutionz.org/" target="_blank" className="text-gray-800 text-hover-primary">
                        Simple Solutionz
                    </a> */}
        </p>
      </div>

      {/* <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                <li className="menu-item">
                    <a href="_" target="_blank" className="menu-link px-2"></a>
                </li>

                <li className="menu-item"></li>
            </ul> */}
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { Footer }
