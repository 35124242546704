import { useLoader } from '../../../app/context/LoaderContext';

const loaderGif = require('../../assets/images/Loader.gif');

const LoaderModal = () => {
  const { showModal } = useLoader();

  if (!showModal) {
    return null;
  }

  return (
    <div
      className="position-fixed top-0 start-0 w-100 h-100"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999 }}
    >
      <div className="position-absolute top-50 start-50 translate-middle z-1">
        <div className="h-auto rounded overflow-hidden w-auto ">
          <div className="bg-white p-2 rounded-circle">
            <img
              src={loaderGif}
              alt="Loader-GIF"
              style={{ width: '100px', height: '100px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderModal;
