/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useLoader } from '../../context/LoaderContext'
import { onForegroundMessage } from '../../../_metronic/helpers/notification'

const DashboardPage = (props) => (
  <>
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-xl-6'>
        <div className='card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow'>
          <div className='card-body d-flex flex-column'>
            <div className='d-flex flex-column flex-grow-1'>
              <Link
                to='/manage-user/consumer'
                className='text-dark text-hover-primary fw-bolder fs-3 mb-4'
              >
                Total Registered Users
              </Link>
              <div className='mt-5'>
                <span className='text-primary fw-bolder fs-3x me-2 lh-0'>
                  {props.stats?.totalUsers}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-6'>
        <div className='card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow'>
          <div className='card-body d-flex flex-column'>
            <div className='d-flex flex-column flex-grow-1'>
              <Link
                to='/manage-user/provider'
                className='text-dark text-hover-primary fw-bolder fs-3 mb-4'
              >
                Total Providers
              </Link>
              <div className='mt-5'>
                <span className='text-dark fw-bolder fs-3x me-2 lh-0'>
                  {props.stats?.totalProviders}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* </div> */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-xl-6 mt-3'>
        <div className='card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow'>
          <div className='card-body d-flex flex-column'>
            <div className='d-flex flex-column flex-grow-1'>
              <Link
                to='/booking'
                className='text-dark text-hover-primary fw-bolder fs-3 mb-4'
              >
                Total Bookings
              </Link>
              <div className='mt-5'>
                <span className='text-success fw-bolder fs-3x me-2 lh-0'>
                  {props.stats?.totalBookings}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-6 mt-3'>
        <div className='card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow'>
          <div className='card-body d-flex flex-column'>
            <div className='d-flex flex-column flex-grow-1'>
              <Link
                to='/services'
                className='text-dark text-hover-primary fw-bolder fs-3 mb-4'
              >
                Total Services
              </Link>
              <div className='mt-5'>
                <span className='text-info fw-bolder fs-3x me-2 lh-0'>
                  {props.stats?.totalServices}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-6 mt-3'>
        <div className='card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow'>
          <div className='card-body d-flex flex-column'>
            <div className='d-flex flex-column flex-grow-1'>
              <Link
                to='/services'
                className='text-dark text-hover-primary fw-bolder fs-3 mb-4'
              >
                Total Categories
              </Link>
              <div className='mt-5'>
                <span className='text-warning fw-bolder fs-3x me-2 lh-0'>
                  {props.stats?.totalCategories}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-6 mt-3'>
        <div className='card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow'>
          <div className='card-body d-flex flex-column'>
            <div className='d-flex flex-column flex-grow-1'>
              <Link
                to='/products'
                className='text-dark text-hover-primary fw-bolder fs-3 mb-4'
              >
                Total Packages
              </Link>
              <div className='mt-5'>
                <span className='text-danger fw-bolder fs-3x me-2 lh-0'>
                  {props.stats?.totalProducts}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()

  const { showLoader, closeLoader } = useLoader()

  const [stats, setStats] = useState({})

  const navigate = useNavigate()

  useEffect(() => {
    onForegroundMessage(navigate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('kt-auth-react-v.api_token')
    showLoader()
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/dashboard-stats`, {
        headers: { Authorization: ` ${token}` },
      })
      .then((res) => {
        setStats(res.data.result)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        closeLoader()
      })
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage stats={stats} />
    </>
  )
}

export { DashboardWrapper }
