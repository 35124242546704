import { createContext, useContext, useMemo, useState } from 'react';

export const LoaderContext = createContext({
  showModal: false,
  showLoader() {},
  closeLoader() {},
});

const LoaderContextProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  const showLoader = () => {
    setShowModal(true);
  };

  const closeLoader = () => {
    setShowModal(false);
  };

  const values = useMemo(
    () => ({ showModal, showLoader, closeLoader }),
    [showModal]
  );

  return (
    <LoaderContext.Provider value={values}>{children}</LoaderContext.Provider>
  );
};

export const useLoader = () => useContext(LoaderContext);

export default LoaderContextProvider;
