import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'

const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
const ServicePage = lazy(() => import('../pages/services/ServicesWrapper'))
const CategoryPage = lazy(() => import('../pages/category/CategoryWrapper'))
const ProductPage = lazy(() => import('../pages/product/ProductWrapper'))
const ProductsGroupPage = lazy(
  () => import('../pages/productsGroup/ProductsGroupWrapper')
)
const CompanyEmployeePage = lazy(
  () => import('../pages/manageUsers/companyEmployee/CompanyEmployeeWrapper')
)
const ConsumerPage = lazy(
  () => import('../pages/manageUsers/consumer/ConsumerWrapper')
)
const ProviderPage = lazy(
  () => import('../pages/manageUsers/provider/ProviderWrapper')
)

const BookingPage = lazy(() => import('../pages/booking/BookingWrapper'))

const AccountListPage = lazy(() => import('../pages/acccount/AccountWrapper'))
const BookingDetailPage = lazy(
  () => import('../pages/booking/BookingDetailWrapper')
)

const PaymentTransactionPage = lazy(
  () => import('../pages/paymentTransaction/paymentTransactionWrapper')
)

const Banners = lazy(() => import('../pages/banner/BannerWrapper'))

const TransactionPage = lazy(
  () => import('../pages/transaction/TransactionWrapper')
)

const Commission = lazy(() => import('../pages/commission/CommissionWrapper'))

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='services' element={<ServicePage />} />
        <Route path='service/:serviceId/category' element={<CategoryPage />} />
        <Route path='products' element={<ProductPage />} />
        <Route path='product-group' element={<ProductsGroupPage />} />
        <Route
          path='manage-user/company-employee'
          element={<CompanyEmployeePage />}
        />
        <Route path='manage-user/consumer' element={<ConsumerPage />} />
        <Route path='manage-user/provider' element={<ProviderPage />} />
        <Route path='booking' element={<BookingPage />} />
        <Route path='account' element={<AccountListPage />} />
        <Route
          path='payment-transaction'
          element={<PaymentTransactionPage />}
        />
        <Route path='booking/:bookingId' element={<BookingDetailPage />} />
        <Route path='banner-management' element={<Banners />} />
        <Route path='transaction' element={<TransactionPage />} />
        <Route path='commission' element={<Commission />} />
        {/* Lazy Modules */}
        <Route
          path='settings'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
