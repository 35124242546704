import { initializeApp } from '@firebase/app'
import {
  getMessaging,
  getToken,
  NotificationPayload,
  onMessage,
} from 'firebase/messaging'
import { userNotification } from '../../app/modules/auth/core/_requests'

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_VAPID_KEY,
} = process.env

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

export const requestNotificationPermission = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: REACT_APP_FIREBASE_VAPID_KEY,
    })

    await userNotification(token)

    console.log('Firebase Token:', token)
    return token
  } catch (error) {
    console.log('firebase error', error)
  }
}

export const onForegroundMessage = (navigate: any) => {
  onMessage(messaging, (payload) => {
    console.log('Message received:', payload)
    const { title, body } = payload.notification as NotificationPayload
    new Notification(title ?? '', { body })
    if (payload.data && payload.data.notification_type && payload.data.id) {
      // Show custom UI notification (optional)
      const url = `/${payload.data.notification_type}/${payload.data.id}`
      navigate(url)
    }
  })
}

export const registerNotificationServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered:', registration)
        return navigator.serviceWorker.ready
      })
      .then((registration) => {
        console.log('Service Worker is ready:', registration)
        requestNotificationPermission()
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error)
      })
  } else {
    console.error('Service workers are not supported in this browser.')
  }
}
