import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import { toast } from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import OTPImage from "../../../../_metronic/assets/images/otp_img.png";
import { toastErrorDisplay } from "../../../../_metronic/helpers/toastErrorDisplay";
import { authResendOtpAPI, authVerifyOtpAPI } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { setAuth } from "../core/AuthHelpers";
import { useNavigate } from "react-router-dom";

const OTPModal = ({ showModal, handleModal, token }) => {
  const [otp, setOtp] = useState("");
  const [saveToken, setSaveToken] = useState(null);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const { auth, setCurrentUser, saveAuth, saveAuthToken } = useAuth();
  const navigate = useNavigate();

  const handleOTPSubmit = async (e) => {
    // e.preventDefault();
    try {
      const payloadBody = {
        id: auth.id,
        token: auth.token,
        code: otp,
      };

      const res = await authVerifyOtpAPI(payloadBody);
      if (res?.data?.result) {
        saveAuth(res.data);
        saveAuthToken(res.data);
        setAuth(res.data);
        setCurrentUser(res?.data?.result);
        localStorage.setItem("device_code", res.data.device_code);
        navigate("/dashboard");
      }

      //   if (!status) {
      //     toast.error("You are not authorize for this app");
      //   }
      //   setDeviceCode(res.device_code);
      handleModal();
    } catch (error) {
      console.log("error", error);
      toast.error(error.errorTitle);
    }
  };
  const handleResendOTP = async (e) => {
    e.preventDefault();
    setLoadingProcess(true);
    try {
      const payloadBody = {
        id: auth.id,
        token: auth.token,
      };
      await authResendOtpAPI(payloadBody);

      toast.success("New OTP sent on your email");
    } catch (error) {
      toastErrorDisplay(error);
    } finally {
      setLoadingProcess(false);
    }
  };

  useEffect(() => {
    if (token) {
      setSaveToken(token);
    }
  }, [token]);

  return (
    <Modal
      show={showModal}
      onHide={handleModal}
      size="lg"
      className="otp-modal-section"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title className="fw-bold fs-np-22">Enter OTP</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <div className=" px-3 pt-5">
          <div className="text-center">
            <img src={OTPImage} alt="otp image" />
            <h1 className="font-24 font-weight-600 mt-3 ">OTP Verification</h1>
            <p>Enter the OTP send on your email to verify</p>
          </div>
          {/* <form
            onSubmit={handleOTPSubmit}
          > */}
          <div className="form-group">
            <OtpInput
              // inputType="number"
              containerStyle={{
                justifyContent: "center",
                fontSize: "3rem",
                padding: "1rem",
              }}
              value={otp}
              onChange={setOtp}
              placeholder="------"
              isInputNum={true}
              shouldAutoFocus={true}
              numInputs={6}
              renderSeparator={<span> &nbsp;</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <div className="text-center py-3">
            <button
              type="button"
              className="btn bg-primary text-white px-5 btn-sm"
              id="btn-submit-otp"
              onClick={() => handleOTPSubmit()}
              //   onClick={() => {
              //     console.log("ad");
              //   }}
            >
              {" "}
              Verify
            </button>
          </div>
          <div className="text-end pb-3 ">
            <button
              type="button"
              onClick={handleResendOTP}
              className="btn btn-link "
              style={{
                color: "#424242",
                fontWeight: "bold",
                // paddingBottom: "4px",
                // lineHeight: "1",
                padding: 0,
                // textDecoration: "none",
              }}
              id="btn-submit4"
              disabled={loadingProcess}
            >
              {loadingProcess && (
                <Spinner
                  className="spinner-border-sm me-2"
                  animation="border"
                  variant="dark"
                />
              )}
              Resend OTP
            </button>
          </div>
          {/* </form> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OTPModal;
